import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'

interface OnboardingListState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };
  serverErrors: any;

  documents: any;
  documentsSet: any;
}

const $state: OnboardingListState = {
  loading: false,
  loaded: false,
  fatalError: false,

  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },
  serverErrors: null,

  documents: null,
  documentsSet: null,
}

const AVAILABLE_FIELDS = ['position']

export const $actions: ActionTree<OnboardingListState, RootState> = {
  loadData({ commit, getters, dispatch }, query) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      const params: any = {}

      if (query.sortField) {
        params.order_by = query.sortField
        params.order_direction = query.sortDirection || 'asc'
      }

      params.page = query.page || 1

      if (query.name) {
        params.search = query.name
      }

      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/onboardings', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')

          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  changeFormValue({ commit, state }, { field, newValue, id, index, indexNext, set }) {
    commit('CHANGE_VALUE', { field, newValue })

    if (set === 1) {
      const ids = state.documents[indexNext].id

      return new Promise((resolve, reject) => {
        const vm = (this as any)._vm

        const cleanForm: any = {}
        for (const key of AVAILABLE_FIELDS) {
          cleanForm[key] = state.documents[indexNext].position
        }
        const nextFrom: any = {}
        for (const key of AVAILABLE_FIELDS) {
          nextFrom[key] = state.documents[index].position
        }


        commit('START_LOADING')

        let request = null
        let secondRequest = null

        request = vm.$http.patch(`/admins/rest/v1/onboardings/${id}`, {
          onboarding: cleanForm,
        })

        secondRequest = vm.$http.patch(`/admins/rest/v1/onboardings/${ids}`, {
          onboarding: nextFrom,
        })

        secondRequest
          .then((response: AxiosResponse) => {
            commit('LOADED_SUCCESS')

            resolve(response.data)
          })
          .catch((errorResponse: AxiosError) => {
            // eslint-disable-next-line
            console.error('Fatal error', errorResponse)

            if (errorResponse.response?.status === 500) {
              commit('LOADED_ERROR')
              reject()
            }
            else {
              const serverError = errorResponse.response?.data?.error

              commit('LOADED_SUCCESS')
              commit('SET_SERVER_ERROR', serverError)
              reject(serverError)
            }
          })

        request
          .then((response: AxiosResponse) => {
            commit('LOADED_SUCCESS')

            resolve(response.data)
          })
          .catch((errorResponse: AxiosError) => {
            // eslint-disable-next-line
            console.error('Fatal error', errorResponse)

            if (errorResponse.response?.status === 500) {
              commit('LOADED_ERROR')
              reject()
            }
            else {
              const serverError = errorResponse.response?.data?.error

              commit('LOADED_SUCCESS')
              commit('SET_SERVER_ERROR', serverError)
              reject(serverError)
            }
          })
      })
    }
    else {
      const ids = state.documentsSet[indexNext].id

      return new Promise((resolve, reject) => {
        const vm = (this as any)._vm

        const cleanForm: any = {}
        for (const key of AVAILABLE_FIELDS) {
          cleanForm[key] = state.documentsSet[indexNext].position
        }
        const nextFrom: any = {}
        for (const key of AVAILABLE_FIELDS) {
          nextFrom[key] = state.documentsSet[index].position
        }


        commit('START_LOADING')

        let request = null
        let secondRequest = null

        request = vm.$http.patch(`/admins/rest/v1/onboardings/${id}`, {
          onboarding: cleanForm,
        })

        secondRequest = vm.$http.patch(`/admins/rest/v1/onboardings/${ids}`, {
          onboarding: nextFrom,
        })

        secondRequest
          .then((response: AxiosResponse) => {
            commit('LOADED_SUCCESS')

            resolve(response.data)
          })
          .catch((errorResponse: AxiosError) => {
            // eslint-disable-next-line
            console.error('Fatal error', errorResponse)

            if (errorResponse.response?.status === 500) {
              commit('LOADED_ERROR')
              reject()
            }
            else {
              const serverError = errorResponse.response?.data?.error

              commit('LOADED_SUCCESS')
              commit('SET_SERVER_ERROR', serverError)
              reject(serverError)
            }
          })

        request
          .then((response: AxiosResponse) => {
            commit('LOADED_SUCCESS')

            resolve(response.data)
          })
          .catch((errorResponse: AxiosError) => {
            // eslint-disable-next-line
            console.error('Fatal error', errorResponse)

            if (errorResponse.response?.status === 500) {
              commit('LOADED_ERROR')
              reject()
            }
            else {
              const serverError = errorResponse.response?.data?.error

              commit('LOADED_SUCCESS')
              commit('SET_SERVER_ERROR', serverError)
              reject(serverError)
            }
          })
      })
    }

  },

}

export const $mutations: MutationTree<OnboardingListState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },

  SET_ITEMS_LIST(state, data) {
    state.documents = data.onboardings
    state.documentsSet = data.onboardingsSet

    state.lastMeta = {
      page: data.meta.page,
      pageSize: data.meta.per_page,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    }
  },

  CHANGE_VALUE(state: OnboardingListState, { field, newValue }) {
    state.documents[field] = newValue
  },
}

export const $getters: GetterTree<OnboardingListState, RootState> = {}

export const STORE_KEY = '$_onboarding_list'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
